<template>
  <div class="itemBox" @click="goVideoDec()">
    <div class="ind">
      <!-- <img src="../../../assets/images/logo2.png" alt="" /> -->
      <img :src="it.videoThumbnail" alt="" />
      <!-- <div class="time">23:45</div> -->
    </div>
    <div class="dec">
      <div class="p">
        {{ it.videoTitle }}
      </div>
      <div class="timeBox">
        <span>{{ it.userNickName }}</span>
      </div>
      <div class="ft">
        <div class="l">
          <!-- <div class="item">播放量：{{ it.playNum }}</div>
          <div class="item">点赞量：{{ it.loveNum }}</div> -->
          <span>上传时间：{{ uti.changeTime(it.updateTime) }}</span>
        </div>
        <div class="r">
          <div class="item">
            <!-- <svg class="icon svg-icon" aria-hidden="true">
              <use xlink:href="#icon-shoucang_shixin"></use>
            </svg> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Store from "../../../store";
export default {
  components: {},
  mounted() {},
  props: {
    it: {
      type: Object,
    },
  },
  data() {
    return {
      uti: Store.uti,
      store: Store.state,
      i: {
        boutiqueStatus: "", //	是否精品 1.是 0.否	integer
        createTime: "", //	创建时间	string
        id: "", //	视频id	integer
        loveNum: "", //	点赞量	integer
        playNum: "", //	播放量	integer
        updateTime: "", //	更新时间	string
        userNickName: "", //	用户昵称	string
        videoThumbnail: "", //	视频封面	string
        videoTitle: "", //	视频标题	string
      },
    };
  },
  methods: {
    // 收藏视频/取消收藏视频
    async addLove() {
      if (!this.store.user) {
        return this.$message.error("请先登录");
      }
      // https://www.youtube.com/watch?v=riJYZZIybcc
      let pat = "";
      if (this.it.actVideoDec.collected == 0) {
        pat = "/videoCollect/add";
        console.log("收藏");
      } else {
        pat = "/videoCollect/cancel";
        console.log("取消收藏");
      }
      let { data: res } = await this.$http.post(pat, {
        userId: this.store.user.userId,
        videoId: this.store.actVideoDec.id,
      });
      if (res.code != 200) {
        return this.$message.error(res.message);
      }
      this.$message.success("操作成功");
      if (pat == "/videoCollect/add") {
        this.store.actVideoDec.collected = 1;
      } else {
        this.store.actVideoDec.collected = 0;
      }
    },
    // 前往视频详情
    goVideoDec() {
      // 设置地址;
      Store.setActVideoId(this.it.id);
      this.$router.push("/mVideoDec");
    },
  },
};
</script>

<style lang="less" scoped>
.itemBox {
  /* 矩形 14 */
  margin-top: (10 / 3.75vw);
  display: flex;
  /* 矩形 6 */
  width: 100%;
  height: (102 / 3.75vw);
  border-radius: (10 / 3.75vw);
  background: #ffffff;
  box-shadow: 0px 4px (10 / 3.75vw) 0px rgba(153, 153, 153, 0.3);
  .ind {
    /* 矩形 15 */
    background-image: url("../../../assets/images/logo2.png");
    background-size: 100% 100%;
    background: #debfbf;
    position: relative;
    /* 矩形 22 */

    width: (285 / 3.75vw);
    height: (102 / 3.75vw);
    border-radius: (5 / 3.75vw);
    box-shadow: 0px 4px (10 / 3.75vw) 0px rgba(153, 153, 153, 0.3);
    overflow: hidden;
    img {
      width: 100%;
      height: 100%;
      transform: scaleY(1.35);
    }
    .start {
      font-size: (128 / 3.75vw);
      position: absolute;
      width: (128 / 3.75vw);
      width: 100%;
      text-align: center;
      background-color: #0006;
      height: 100%;
      line-height: (170 / 3.75vw);
      .in {
        color: #fff;
      }
      .err {
        color: #ff3a3a;
      }
    }
    .time {
      position: absolute;
      bottom: (12 / 3.75vw);
      right: (13 / 3.75vw);
      /* 34:16 */
      font-family: SourceHanSansCN-Regular;
      font-size: (12 / 3.75vw);
      font-weight: normal;
      letter-spacing: 0em;
      color: #ffffff;
    }
  }
  .dec {
    flex: 1;
    padding: (10 / 3.75vw);

    height: (102 / 3.75vw);
    box-sizing: border-box;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .p {
      /* 稳定币发展历程稳定币发展历... */

      width: (172 / 3.75vw);
      height: (35 / 3.75vw);
      font-family: PingFang SC Bold;
      font-size: 12px;
      font-weight: normal;
      letter-spacing: 0em;
      color: #333333;
      overflow: hidden;

      text-overflow: ellipsis;

      display: -webkit-box;

      -webkit-box-orient: vertical;

      -webkit-line-clamp: 2;
    }
    .time {
      height: (17 / 3.75vw);
      font-family: SourceHanSansCN-Regular;
      font-size: (12 / 3.75vw);
      font-weight: normal;
      letter-spacing: 0em;
      color: #999999;
      margin-bottom: (16 / 3.75vw);
    }
    .ft {
      box-sizing: border-box;
      display: flex;
      justify-content: space-between;
      height: (17 / 3.75vw);
      font-family: SourceHanSansCN-Regular;
      font-size: (12 / 3.75vw);
      font-weight: normal;
      letter-spacing: 0em;
      color: #999999;
      .l {
        flex: 1;
        display: flex;
        .item {
          margin-right: (53 / 3.75vw);
          font-size: (14 / 3.75vw);
        }
      }
      .r {
        // display: flex;
        // display: inline-flex;
        .item {
          margin-left: (8 / 3.75vw);
          font-size: (14 / 3.75vw);
          display: inline-block;
        }
      }
    }
  }
}
</style>
