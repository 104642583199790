<template>
  <div class="viewBox">
    <div class="box">
      <van-list
        v-model="loading"
        :finished="finished"
        finished-text="没有更多了"
        @load="getMyCollect()"
        class="act-van-list"
      >
        <item-box
          :it="item"
          v-for="(item, index) in list"
          :key="index"
        ></item-box>
      </van-list>
    </div>
  </div>
</template>
<script>
import Store from "../../../store";
import itemBox from "./item-box.vue";
export default {
  components: { itemBox },
  mounted() {
    // this.getMyCollect();
  },
  data() {
    return {
      store: Store.state,
      uti: Store.uti,

      loading: false,
      finished: false,

      total: 0,
      pageNum: 1,
      pageSize: 10,

      list: [
        // {
        //   boutiqueStatus: "0", //	是否精品 1.是 0.否	integer
        //   createTime: [2022, 4, 5], //	创建时间	string
        //   id: "123456+", //	视频id	integer
        //   loveNum: "456", //	点赞量	integer
        //   playNum: "789", //	播放量	integer
        //   updateTime: [2022, 4, 5], //	更新时间	string
        //   userNickName: "黑心老板", //	用户昵称	string
        //   videoThumbnail:
        //     "https://polyjetclub-backend.polysmartchain.com/images/normal/866.png", //	视频封面	string
        //   videoTitle: "黑心老板就是好", //	视频标题	string
        // },
      ],
    };
  },
  methods: {
    // 获取我的收藏
    async getMyCollect() {
      this.loading = true;
      if (!this.store.user.userId) {
        return this.$message.warning("请先登录");
      }
      let { data: res } = await this.$http.post(
        `/videoCollect/myList/${this.store.user.userId}`,
        {
          pageNum: this.pageNum,
          pageSize: this.pageSize,
          userId: this.store.user.userId,
        }
      );
      if (res.code != 200) {
        return this.$message.error(res.message);
      }
      this.loading = false;
      this.total = Number(res.data.total);
      // this.list = res.data.records;
      this.list = this.uti.addArr(this.list, res.data.records);
      // 如果超出就
      if (this.pageNum * this.pageSize >= this.total) {
        this.finished = true;
      } else {
        this.pageNum++;
      }
    },

    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.getMyCollect();
    },
  },
};
</script>

<style lang="less" scoped>
.viewBox {
  .box {
    margin-bottom: (50 / 3.75vw);
  }
}
</style>
